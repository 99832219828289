import { Link } from "react-router-dom";
import "./index.scss";

const Home = () => {
  return (
    <div className="container home-page">
      <div className="text-zone">
        <h1 className='default-text'>
          Hey! <br /> I'm <h1 alt="developer"> Jordan</h1>
          Software Engineer
        </h1>
        <h2>Currently working at Yieldify</h2>
        <h2>Open to creative opportunities and work</h2>
        <Link to="/contact" className="flat-button">
          CONTACT ME
        </Link>
      </div>
    </div>
  );
};

export default Home;
