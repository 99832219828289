// import { Link } from "react-router-dom";
import "./index.scss";

const About = () => {
  return (
    <div className="container home-page">
      <div className="text-zone">
        <h1 className="default-text">
          About Me <br />
          <div className="aboutMeText">
            <p> Currently working as a Technical Solutions Engineer</p>
            <p> Current projects:</p>
            <a
              className="scrollerClass"
              href="https://nasascroller.jordanwu.co.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Nasa Daily Astronomy Photo viewer
            </a>
            <br/>
            <a
              className="scrollerClassGithub"
              href="https://github.com/jordanwuuuu/NasaDailyImageScroller"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Github Link
            </a>
          </div>
        </h1>
      </div>
    </div>
  );
};

export default About;
