import "./index.scss";

const Contact = () => {
  return (
    <div className="container home-page">
      <div className="text-zone">
        <h1 className="default-text">
          Contact Me <br />
          <h3>
            Email: jordanwu1996@gmail.com
            <br />
            Number: 07775799353
          </h3>
        </h1>
      </div>
    </div>
  );
};

export default Contact;
